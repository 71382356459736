import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking, { useFilterFields } from 'pages/_templates/Ranking';

import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';

import { requestDriversRanking } from './services';
import { categorias, columns, getExcelFields, fields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import { generateFileName } from 'utils/generateFileName';

import ExcelModal from 'components/ExcelModalNew';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const {
    planosAtivos,
    isOpLogistico,
    isProvider,
    hasTorrePlus,
    hasMonitoramento,
    hasTelemetria,
    hasFadiga,
  } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);

  const [openExcelModal, setOpenExcelModal] = useState(false);

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.motoristas_ranking;
  });

  const [tab, setTab] = useState('geral');
  const [query, setQuery] = useState(null);
  const [podium, setPodium] = useState([]);

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    data: resData,
    refetch,
  } = useQuery(
    ['ranking', query],
    () => query && requestDriversRanking({ ...query }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleRefetch = () => {
    refetch();
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setTab(newValue);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: tab,
    items: categorias.filter(
      item =>
        !item.plan ||
        planosAtivos.some(plano => plano.id_do_plano === item.plan),
    ),
    onChange: handleStatusTab,
    disabled: isFetching,
  };

  useEffect(() => {
    setQuery({ ...filtersPersist, tipo: 'motorista', tab });
  }, [filtersPersist]);

  useEffect(() => {
    if (resData?.data?.data) {
      const podio = resData.data.data[tab]?.slice(0, 3) ?? [];
      setPodium(
        podio.map(item => ({
          nameOne: item.nome ?? item.nome_motorista,
          position: item.posicao,
          oldPosition: item.posicao_anterior
            ? item.posicao_anterior > item.posicao
              ? 'UP'
              : item.posicao_anterior < item.posicao
              ? 'DOWN'
              : 'EQUAL'
            : 'UP',
          logo: item.foto,
          value: item.valor ?? item.total_pontos,
        })),
      );
    }
  }, [resData, tab]);

  const swapConditional = pts_km_hr => {
    return [
      {
        condition: (value, item) =>
          Number(item[pts_km_hr]) > Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
      {
        condition: (value, item) =>
          Number(item[pts_km_hr]) <= Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.success.light,
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },
    ];
  };

  const swapColumns = (columns, colId1, colId2) => {
    const index1 = columns.findIndex(col => col.id === colId1);
    const index2 = columns.findIndex(col => col.id === colId2);

    if (index1 !== -1 && index2 !== -1) {
      const newColumns = [...columns];

      const col1 = { ...newColumns[index1] };
      const col2 = { ...newColumns[index2] };

      delete col1.conditional;

      newColumns[index1] = col2;
      newColumns[index2] = col1;

      if (newColumns[index1].id === 'pontos_km') {
        newColumns[index1].conditional = swapConditional(newColumns[index1].id);
      }
      if (newColumns[index1].id === 'pontos_hora') {
        newColumns[index1].conditional = swapConditional(newColumns[index1].id);
      }

      return newColumns;
    }

    return columns;
  };

  const formattedColumns = useMemo(() => {
    let reorderedColumns = [...columns[tab]];

    if (tab === 'geral') {
      if (hasMonitoramento && !(hasTelemetria && hasFadiga))
        reorderedColumns = swapColumns(
          reorderedColumns,
          'total_pontos',
          'pontos_hora',
        );
      if ((hasTelemetria || hasFadiga) && !hasMonitoramento)
        reorderedColumns = swapColumns(
          reorderedColumns,
          'total_pontos',
          'pontos_km',
        );
    }

    return reorderedColumns
      ?.filter(
        col =>
          !col.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            col.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        if (item.id === 'nome_motorista') {
          if (hasTorrePlus)
            return {
              ...item,
              subRow: {
                value: (_, item) => item?.nome_empresa,
              },
            };
          if (!isOpLogistico)
            return {
              ...item,
              subRow: {
                children: (_, item) => (
                  <span style={{ textTransform: 'none' }}>
                    Filial: {item?.nome_filial}
                  </span>
                ),
              },
            };
        }
        return item;
      });
  }, [query, isOpLogistico, tab]);

  const actions = [
    {
      title: 'Ver perfil',
      function: id => {
        if (id) navigate(`/motoristas/${id}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    return fields[tab]
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const data = resData?.data?.resumo?.[tab]?.[item.id] || '';
        return {
          ...item,
          value: item.value ? item.value(data) : data,
        };
      });
  }, [resData, fields, tab]);

  const getDataMedia = useCallback((data, tab) => {
    const getMedia = {
      geral: (resumo, data) => resumo.pontos / data.length,
      cabine: resumo => resumo.pontos_hora,
      telemetria: resumo => resumo.pontos_km,
      fadiga: resumo => resumo.pontos_km,
      manual: resumo => resumo.pontos,
      descarga: resumo => resumo.pontos_descarga,
    };
    return (
      data?.data?.[tab].map(item => ({
        ...item,
        media: getMedia[tab](data.resumo[tab], data.data[tab]),
      })) ?? []
    );
  }, []);

  const filterFields = useFilterFields();

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={getExcelFields(tab).filter(
            item =>
              !item.plans ||
              planosAtivos.some(({ id_do_plano }) =>
                item.plans.includes(id_do_plano),
              ),
          )}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            isProvider
              ? '/excel-provider/motoristas_ranking'
              : '/excel/motoristas_ranking'
          }
          filtersPersis={filtersPersist}
          collumns={filterFields}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        filterKey="motoristas_ranking"
        headerInfo=""
        handleRefetch={handleRefetch}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        headerTabs={headerTabs}
        podium={podium}
        loading={isFetching}
        tableProps={{
          loading: isFetching,
          data: getDataMedia(resData?.data, tab),
          columns: formattedColumns,
          actions,
          searchKeys: ['nome_motorista'],
          placeholder: 'Buscar por Motorista',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          searchEvent: search =>
            trackEvent(user, 'Busca Ranking de Motoristas', null, search),
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontrados motoristas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingMotoristas;
